<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="CargarManual"
    >
     
       <CRow>        
        <CCol sm="12" lg="12" class="mb-2">
          <vue-dropzone 
              ref="myVueDropzone" 
              id="dropzone" 
              :options="dropzoneOptions" 
              :useCustomSlot="true"
              v-on:vdropzone-files-added="sendingEvent"
              v-on:vdropzone-removed-file="deleteFile"
              v-on:vdropzone-error="DropzoneError"
          > 
              <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                  <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
              </div>
          </vue-dropzone>

        </CCol>        
        <CCol sm="12" lg="10">
            <CInput
              :label="$t('label.description')"
              :horizontal="{label:'col-sm-9 col-lg-4',input:'col-sm-11 col-lg-8'}"
              maxlength="250"
              v-model.trim="CommodityDocDs"
              addLabelClasses="text-right required"
              :is-valid="hasError($v.CommodityDocDs)"
              :invalid-feedback="errorMessage($v.CommodityDocDs)"
            />
        </CCol>
        <CCol sm="11" lg="2">
          <CButton color="add" square size="sm" @click="submit">
              <CIcon name="checkAlt"/>
            </CButton>
        </CCol>

        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="items"
            :fields="fields"
            :loading="LoadingTabla"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="tableText.itemsPerPage"
            hover
            small
            sorter
            pagination
          >
            <template #loading>
              <loading/>
            </template>
            <template #Down="{item}">
              <td class="py-2 center-cell">
                <a class="btn btn-sm btn-watch" style="margin-right:2px;" v-c-tooltip="{
                            content: $t('label.openDocumentation')
                          }" target='_blank' :href="item.Down" >
                  <CIcon name="eye" />
                </a>
                <CButton color="wipe" square size="sm" v-c-tooltip="{
                            content: $t('label.delete')
                          }" @click="deleteCommodityManual(item)" >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="wipe" size="sm" @click="CargarManual= false">
            <CIcon name="x"/>&nbsp;
            {{$t('button.exit')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>
<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
    .icon{
        background-color: null; 
        border: 0px;
    }
</style>

<script>
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import commodityValidations from '@/_validations/rubro/commodityValidations';


let user = JSON.parse(localStorage.getItem('user'));

function fields(){ 

return[
  { 
    key: 'Nro', 
    label: '#',
    _style: 'width:1%;',
    filter:false
  },
  { key: 'CommodityDocDs', label: this.$t('label.handlingManuals'), _classes: 'text-uppercase text-center' },
  { key: 'Usuario', label:this.$t('label.user'), _classes: 'text-uppercase text-center'},
  { key: 'Fecha', label:this.$t('label.date'),_classes:'text-center', _classes: 'text-uppercase text-center' },
  { 
    key: 'Down', 
    label: '',
    sorter: false,
    filter: false,
    _style: 'width:15%;',
    _classes:'center-cell'
  },  
]

};
function data () {
  return {
    items: [],
    CommodityId: '',
    RouteCommodityDoc: '',
    CommodityDocDs: '',
    CommodityDocId: '',
    Loading: false,
    LoadingTabla: false,
    CargarManual: false,
    tituloModal: null,
    docFile: null,
    uploadPercent: 0,
    responsePercent: 0,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
          this.removeAllFiles();
          this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx',
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`
      }
    }
  }
}

//FUNCIONES DE METHOD
function filtroItems(listado) {
  this.items = listado
  .filter(listado => listado.FgActCommodityDoc)
  .map(listado => Object.assign({}, this.items, {
    Nro: listado.Nro,
    CommodityDocId: listado.CommodityDocId,
    CommodityDocDs: listado.CommodityDocDs,
    RouteCommodityDoc: listado.RouteCommodityDoc ? listado.RouteCommodityDoc.replace('Public/Commodity/', '', 'i'):'N/A',
    Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
    Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
    Down: listado.RouteCommodityDoc ? `${this.$store.getters["connection/getBase"]}${listado.RouteCommodityDoc.replace('Public/', '', 'i')}`:'N/A',
 }));

  return this.items;
}

function getCommodityManualList () {
  this.LoadingTabla = true;
  let listado = Array;
  this.$http.get("CommodityDoc-list", { CommodityId: this.CommodityId })
  .then(response => {
    listado = [...response.data.data];
    this.filtroItems(listado);
    this.LoadingTabla= false;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.LoadingTabla= false;
  });
}

function submitFile() {
  this.Loading = true;
  let formData = new FormData();
  formData.append('CommodityId', this.CommodityId);
  formData.append('File', this.docFile);
  this.$http.post("CommodityDoc-insert", formData, {
      baseURL: `${this.$store.getters["connection/getApiBase"]}files/`,
      onUploadProgress: function( progressEvent ) {
        this.uploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
      }.bind(this),
      onDownloadProgress: function( progressEvent ) {
        this.responsePercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
      }.bind(this)
  }).then((response) => {
    this.RouteCommodityDoc = response.data.data.files[0].path;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() =>
    this.Loading = false
  );
}

function refreshComponent() {
  this.CommodityDocDs    = '';
  this.CommodityDocId    = '';
  this.RouteCommodityDoc = '';
  this.$refs.myVueDropzone.removeAllFiles();
  this.$nextTick(() => { this.$v.$reset() })
}

function deleteCommodityManual(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')+' '} ${item.Nro}?`,
      }))
  .then((result) => {
    if (result.isConfirmed) {
      this.CommodityDocId=item.CommodityDocId;
      this.CommodityDocDs=item.CommodityDocDs;
      this.RouteCommodityDoc=item.RouteCommodityDoc;

    this.Loading = true;
    let res = [];
    let metodo = '';
    let ruta = '';
    let CommodityDocJson = [];
    
    //ASIGNACION DE VARIABLES PARA submit
    if (this.CommodityDocId!=='') {
      CommodityDocJson = {
        CommodityDocId: this.CommodityDocId,
        CommodityId: this.CommodityId,
        CommodityDocDs: this.CommodityDocDs,
        RouteCommodityDoc: this.RouteCommodityDoc,
        Status: 0,
      };
      metodo = 'PUT';
      ruta = "CommodityDoc-update";
    }
    
    this.$http.ejecutar(metodo, ruta, CommodityDocJson, { root: 'CommodityDocJson' })
      .then(response => {
        res = [...response.data.data];
        this.refreshComponent();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.Loading= false;
        this.getCommodityManualList();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.Loading= false;
      });
    }
  });  
}


function submit() {
  try {
    this.$v.$touch();
    const files = this.$refs.myVueDropzone.getQueuedFiles();
    if (this.$v.$invalid && files.length === 0) {
        throw this.$t('label.errorsPleaseCheck');
    }

    if (this.$v.$invalid) return false;

    if(files.length === 0){
      this.$notify({
          group: 'container',
          type: 'error',
          ignoreDuplicates:false,
          title: 'ERROR',
          text: `${this.$t('label.documentQuestion')}`,
      });
      return;
        
    }

    this.Loading = true;

    let formData = new FormData();
    formData.append('CommodityId', this.CommodityId);
    formData.append('File', this.docFile);
    this.$http.post("CommodityDoc-insert", formData, {
        baseURL: `${this.$store.getters["connection/getApiBase"]}files/`,
        onUploadProgress: function( progressEvent ) {
          this.uploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this),
        onDownloadProgress: function( progressEvent ) {
          this.responsePercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this)
    }).then((responseImage) => {
      this.RouteCommodityDoc = responseImage.data.data.files[0].path;
      this.Loading = true;
      let res = [];
      let metodo = '';
      let ruta = '';
      let CommodityDocJson = [];
      let CommodityDocJson2 = {
        RouteCommodityDoc: this.RouteCommodityDoc,
      }
        CommodityDocJson = {
          CommodityId: this.CommodityId,
          CommodityDocDs: this.CommodityDocDs,
          RouteCommodityDoc: this.RouteCommodityDoc,
        };
        metodo = 'POST';
        ruta = "CommodityDoc-insert";
      
      this.$http.ejecutar(metodo, ruta, CommodityDocJson, { root: 'CommodityDocJson' })
      .then(response => {
        res = [...response.data.data];
        this.refreshComponent();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.Loading= false;
        this.getCommodityManualList();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.Loading= false;
      });
    }).catch((error) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    })
    .then(() =>
      this.Loading = false
    );
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function sendingEvent(files){
 // this.$refs.myVueDropzone.removeFile(files);
  if(files[0].name) this.docFile = files[0];
}
function deleteFile(file) {
  this.docFile = null;
}
function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

//Computeds

export default {
  name: 'upload-commodity-manual-modal',
  mixins: [General],
  data,
  props:{
    modal:null
  }, 
  components: {
    vueDropzone: vue2Dropzone
  },
  validations: commodityValidations,
  methods: {
    getCommodityManualList,
    filtroItems,
    submitFile,
    submit,
    refreshComponent,
    deleteCommodityManual,
    sendingEvent,
    deleteFile,
    DropzoneError
  },
  watch: {
    modal: function () {
      if (this.modal) {
        this.refreshComponent();
        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        this.CargarManual = true;
        this.CommodityId = this.modal.CommodityId;
        this.getCommodityManualList();
        this.tituloModal= this.$t('label.uploadHandlingDocumentation') + " : "+this.modal['CommodityName'+_lang];
        this.$emit('cerrarModal');
      }
    }
  },
  computed: {
    fields
}
}
</script>
<style scoped>

</style>